import helperService from '@/services/helperService';
import quoteService from '@/services/quoteService';

const Location = () => import(/* webpackChunkName: "view-Delivery" */ '@/common/location/Location.vue');
const Animate = () => import(/* webpackChunkName: "view-Delivery" */ '@/common/animate/Animate.vue');

export default {
	name: 'pickup',
	data: function() {
		return {
			step: 4,
			submitButtonText: 'Calculate',
			placeholder: 'ie Chicago, IL or 60601',
			size: '',
			styleObject: {},
			default_msg: 'Begin by entering the city of your pickup location.',
			info_msg: '',
			has_error: false,
			selected_city: null,
		};
	},
	components: {
		'location-search': Location,
		'animate-scene': Animate,
	},
	beforeRouteEnter: function(to, from, next) {
		if (from.name || helperService.isValidLandingState(4)) {
			next(true);
		} else {
			next('/');
		}
	},
	mounted: function() {
		this.info_msg = this.default_msg;

		helperService.updateProgress(this.step);

		if (window.analytics) {
			if (quoteService.getMoveLeadToken()) {
				window.analytics.page(
					'Destination',
					{ type: 'page', path: 'Destination' },
					{ integrations: { AdWords: false } }
				);
			} else {
				document.addEventListener('fetchID', () => {
					window.analytics.page(
						'Destination',
						{ type: 'page', path: 'Destination' },
						{ integrations: { AdWords: false } }
					);
				});
			}
		}
	},
	beforeDestroy: function() {
		if (this.selected_city) {
			// let params = helperService.getQueryParams();
			// params.destination = this.selected_city.value;
			// helperService.sendMixpanelData("Destination Selected", params);
		}
	},
	methods: {
		updateBackground: function() {
			const image = helperService.getDeliveryBg();

			this.styleObject = {
				'background-image': 'url(' + image + ')',
			};
		},
		onCitySelect(error, city, locations) {
			this.$log.debug(error, city);

			this.has_error = error;
			this.selected_city = city;

			this.info_msg = this.default_msg;

			if (!city) {
				return false;
			}

			if (error) {
				if (city && !locations.length) {
					this.info_msg = 'No locations found';
				} else {
					this.info_msg = 'Please select a location from the dropdown';
				}

				return;
			}

			this.$router.push('/quote');
		},
	},
};
